/* eslint-disable camelcase */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext, PlainTable, NotificationContext, ConfirmModal, CopyButton } from '@mdc/ui';
import { Button, Col, FormControl, InputGroup, Row, Spinner, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BackendService, logService } from '@mdc/services';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import { CustomContentLoader } from '../../result/customContentLoader/CustomContentLoader';


const MAXIMUM_CLIENTS_ALLOWED = 5;

import './OAuth2Client.scss';

const OAuth2Client = () => {
    const userContext = useContext(UserContext);
    const { notify } = useContext(NotificationContext);

    const { t, ready } = useTranslation();
    const [showAdd, setShowAdd] = useState(false);
    const [inputClientID, setInputClientID] = useState('');
    const [clientsList, setClientsList] = useState(undefined);
    const [selectClientsList, setSelectClientsList] = useState([]);
    const [clientSecret, setClientSecret] = useState({});

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [updatingClientsList, setUpdatingClientsList] = useState('');

    useEffect(() => {
        // Throttle requests
        let discard = false;
        const timeout = setTimeout(() => {
            if (userContext?.data?.sso_user_id) {
                (async () => {
                    try {
                        // Get User's clients
                        await handleListClients(userContext.data.sso_user_id, discard);
                    } catch (e) {
                        if (e?.response?.data?.code === 500200 || e.response.status !== 500200) {
                            // If error code is 500200, this means there aren't any clients added
                            setClientsList([]);
                        } else {
                            logService.error(e);
                            // If there's a different error, we should inform the user
                            notify({
                                message: t('Request failed. Please try again!'),
                                type: NOTIFICATION_TYPES.CRITICAL
                            });
                        }
                    }
                })();
            }
        }, 1000);


        return function () {
            discard = true;
            clearTimeout(timeout);
        };
    }, [userContext]);

    const handleListClients = async (ssoUserId, discard) => {
        const response = await BackendService.listAllClients({
            ssouserid: ssoUserId
        });

        if (response.data && !discard) {
            const clientsListData = response.data.data || [];
            setClientsList(clientsListData);
        }
    };

    const handleAddClient = async (list) => {
        const [promise] = BackendService.createOauthClient({
            clientname: list
        }, {
            ssouserid: userContext?.data?.sso_user_id
        });
        const response = await promise;

        if (response?.data?.error) {
            notify({
                message: response.data.error,
                type: NOTIFICATION_TYPES.CRITICAL
            });
            setUpdatingClientsList('');
            return;
        }

        if (response.data) {
            const allClients = [...clientsList, {
                clientId: response.data.clientId,
                clientName: response.data.clientName,
                clientSecret: response.data.clientSecret,
            }];
            setClientsList(allClients);
        }
    };

    const handleRemoveClient = async (list) => {
        const [promise] = BackendService.removeClient({
            clientId: list.clientId
        }, {
            ssouserid: userContext?.data?.sso_user_id
        });

        const response = await promise;

        if (response.data && response.data.removed === true) {
            setClientsList((last) => last.filter((client1) => client1.clientId === list.clientId).length ? last.filter((client2) => client2.clientId !== list.clientId) : last);
        }
    };

    const handleKeyPress = (e) => {
        return e.key === 'Enter' ? addClient() : undefined;
    };

    const updateClientsList = useCallback((list, type) => {
        setInputClientID('');
        setShowAdd(false);
        setShowDeleteModal(false);

        (async () => {
            try {
                if (type === 'add') {
                    // add new Client
                    await handleAddClient(list);
                } else if (type === 'remove') {
                    // remove Client
                    await handleRemoveClient(list);
                }
            } catch (e) {
                logService.error(e);
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
            }
            setUpdatingClientsList('');
            setSelectClientsList([]);
        })();
    }, [userContext, inputClientID, clientsList]);

    const addClient = useCallback(() => {
        if (inputClientID && clientsList) {
            setUpdatingClientsList('add');
            updateClientsList(inputClientID, 'add');
        }
    }, [updateClientsList, clientsList, inputClientID]);

    const removeClient = useCallback(() => {
        if (clientsList) {
            let list = selectClientsList.filter((client) => clientsList.filter((client2) => client2.clientId === client?.clientId));

            setUpdatingClientsList('remove');

            // Remove all clients from the list
            list.forEach((client) => updateClientsList(client, 'remove'));
        }
    }, [updateClientsList, clientsList, selectClientsList]);

    const modalDeleteClient = useMemo(() => {
        return <ConfirmModal
            className='advancedOptionsModal'
            show={showDeleteModal}
            onAccept={removeClient}
            onCancel={() => setShowDeleteModal(false)}
            title={t('Remove Client')}
            body={<p>{t('Are you sure to remove this client?')}</p>}
            acceptText={t('Yes')}
            cancelText={t('No')}
        />;
    }, [showDeleteModal, removeClient]);

    const retrieveClientSecret = useCallback(async (clientId) => {
        const existingClient = clientsList?.filter((client) => client.clientId === clientId)[0];

        if (existingClient) {
            try {
                const userEntry = await BackendService.listClient(existingClient.clientId, {
                    ssouserid: userContext?.data?.sso_user_id
                });

                const { data: userEntryData } = userEntry;

                setClientSecret({
                    clientId: existingClient.clientId,
                    clientSecret: userEntryData.clientSecret
                });
            } catch (e) {
                logService.error(e);
                notify({
                    message: t('Failed to get users information. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
            }

            return;
        }

        notify({
            message: t('Failed to get client secret. Please try again!'),
            type: NOTIFICATION_TYPES.CRITICAL
        });
    }, [clientsList]);

    const dom = useMemo(() => {
        const isPaidUser = userContext.isPaidUser;

        if (!isPaidUser) {
            return <div className='onlyEnterpriseText'>
                {t('This feature is available only for paid users')}
            </div>;
        }

        if (typeof clientsList === 'undefined') {
            return <CustomContentLoader />;
        }

        let tableBanner;
        if (updatingClientsList) {
            tableBanner = <div className='loadingSpinner'>
                <Spinner animation='border' size={'sm'} />
            </div>;
        } else if (showAdd) {
            tableBanner = <>
                <InputGroup className='clientInputGroup'>
                    <FormControl
                        placeholder='Client Name'
                        aria-label='client name'
                        onChange={(e) => setInputClientID(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        name='Client Name'
                    />
                    <InputGroup.Append>
                        <Button onClick={addClient}>
                            {t('Add')}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </>;
        } else if (!clientsList?.length) {
            tableBanner = <div className='noDataText'>
                {t('In order to manage MD Cloud API Clients, please add a new client')}
            </div>;
        } else if (clientsList?.length === MAXIMUM_CLIENTS_ALLOWED) {
            tableBanner = <div className='noDataText'>
                {t('You\'ve reached the maximum number of allowed API clients ({{maxNumber}}/{{maxNumber}}).', { maxNumber: MAXIMUM_CLIENTS_ALLOWED })}
            </div>;
        }

        return <PlainTable
            customClassName='w-100'
            tableBanner={tableBanner}
            dontCollapse={true}
            data={[...clientsList].reverse().map((client) => ({
                clientName: client.clientName,
                clientSecret: clientSecret.clientId === client.clientId ?
                    <div className='clientSecretWrapper'>
                        <span className='clientSecret'>
                            {clientSecret.clientSecret}
                        </span>
                        <CopyButton icon={'clone'} data={clientSecret.clientSecret} />
                    </div> :
                    <input
                        type='button'
                        disabled={!!updatingClientsList}
                        onClick={() => (async () => retrieveClientSecret(client.clientId))()}
                        value={t('Retrieve Client Secret')}
                        name='Retrieve Client Secret'
                    />,
                clientID: <div className='clientSecretWrapper no-bg'>
                    <span className='clientSecret clientID'>
                        {client.clientId}
                    </span>
                    <CopyButton icon={'clone'} data={client.clientId} />
                </div>,
                action: <input
                    type='checkbox'
                    name='securityRules'
                    disabled={!!updatingClientsList}
                    checked={
                        selectClientsList.filter((clientEntry) => clientEntry.clientId === client.clientId).length
                    }
                    onClick={
                        () => setSelectClientsList((last) => last.filter((client1) => client1.clientId === client.clientId).length ? last.filter((client2) => client2.clientId !== client.clientId) : [...last, client])
                    }
                />
            }))}
            columnsData={[
                {
                    Header: t('Client Name'),
                    accessor: 'clientName',
                    hasSorting: true
                },
                {
                    Header: t('Client ID'),
                    accessor: 'clientID',
                    hasSorting: false
                },
                {
                    Header: t('Client Secret'),
                    accessor: 'clientSecret',
                    hasSorting: false
                },
                {
                    Header: <ButtonGroup aria-label='oauthClientsGroup'>
                        <Button className='addClientBtn' disabled={!clientsList || !!updatingClientsList || clientsList?.length === MAXIMUM_CLIENTS_ALLOWED} onClick={() => setShowAdd((last) => !last)}>{t('Add API Client')}</Button>
                        <Button className='removeClientBtn' variant='danger' disabled={!clientsList || !!updatingClientsList || !selectClientsList?.length} onClick={() => setShowDeleteModal(true)}>{t('Remove')}</Button>
                    </ButtonGroup>,
                    accessor: 'action'
                }
            ]} />;
    }, [t, userContext, showAdd, inputClientID, clientsList, addClient, updatingClientsList, selectClientsList, clientSecret]);

    if (!ready || !userContext) {
        return null;
    }

    return <div className='accountDeveloper'>
        <h2>{t('OAuth2 clients')}</h2>
        <Row>
            <Col className='domCol'>
                {dom}
            </Col>
        </Row>
        {modalDeleteClient}
    </div>;
};

export default OAuth2Client;
